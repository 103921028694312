import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import jsonexport from 'jsonexport';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  CONSOLE_DistributeAllBundleCodesInDownloadClientRedemptionCodesDialog as Data,
  CONSOLE_DistributeAllBundleCodesInDownloadClientRedemptionCodesDialogVariables as Variables,
} from './__generated__/CONSOLE_DistributeAllBundleCodesInDownloadClientRedemptionCodesDialog';
import { getCamelCaseString } from '../../components/accessTextHelpers';
import { DISTRIBUTE_ALL_BUNDLE_CODES_MUTATION } from './DownloadClientBundleRedemptionCodesPDF';

enum DistributedStatus {
  UNDISTRIBUTED = 'UNDISTRIBUTED',
  DISTRIBUTED = 'DISTRIBUTED',
  BOTH = 'BOTH',
}

interface Props {
  close: () => void;
  data: {
    appURL: string;
    accessCodes: Array<any>;
    allTours: Array<{
      tourName: string;
    }>;
  };
}

export const DownloadClientBundleRedemptionCodesCSV = ({
  close,
  data: { accessCodes, allTours, appURL },
}: Props) => {
  const [distributedStatus, setDistributedStatus] = useState<DistributedStatus>(
    DistributedStatus.UNDISTRIBUTED
  );
  const [distribute, setDistribute] = useState<boolean>(
    distributedStatus !== DistributedStatus.DISTRIBUTED
  );
  const [distributeCodes, { loading }] = useMutation<Data, Variables>(
    DISTRIBUTE_ALL_BUNDLE_CODES_MUTATION
  );

  const downloadCSV = async () => {
    try {
      const relevantAccessCodes = getRelevantAccessCodes(
        accessCodes,
        distributedStatus
      );
      const csvData = await exportToCSV(relevantAccessCodes, allTours, appURL);
      const hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
      hiddenElement.target = '_blank';

      //provide the name for the CSV file to be downloaded
      hiddenElement.download = `AncientWorld_RedeemCodes_${format(
        new Date(),
        'yyyyMMdd'
      )}.csv`;

      if (distributedStatus !== DistributedStatus.DISTRIBUTED && distribute) {
        const tokens = accessCodes.map((p) => p.token);
        await distributeCodes({
          variables: {
            input: {
              tokens,
            },
          },
        });
      }

      hiddenElement.click();

      close();
    } catch (error) {
      toast.error('An error occurred while generating CSV file', {
        toastId: 'redeem_codes_csv_error',
        hideProgressBar: true,
        pauseOnHover: false,
      });
    }
  };

  const handleDistributedStatusChange = (event) => {
    setDistributedStatus(event.target.value);
  };

  const handleDistributeUndistributedCodesChange = (event) => {
    setDistribute(event.target.checked);
  };

  return (
    <Dialog
      open
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Download Bundle Redeem Codes as a CSV File
      </DialogTitle>

      <DialogContent>
        <Box>
          <Box>
            <Typography gutterBottom>
              If you want to feed the bundle redeem codes into a separate CMS or
              simply to backup your codes, this feature will be helpful.
            </Typography>

            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Distributed status of the bundle redeem codes to download"
                value={distributedStatus}
                onChange={handleDistributedStatusChange}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={DistributedStatus.UNDISTRIBUTED}
                  control={<Radio />}
                  label="Download only the undistributed bundle redeem codes"
                />
                <FormControlLabel
                  value={DistributedStatus.DISTRIBUTED}
                  control={<Radio />}
                  label="Download only the distributed bundle redeem codes"
                />
                <FormControlLabel
                  value={DistributedStatus.BOTH}
                  control={<Radio />}
                  label="Download all bundle redeem codes"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Divider />

          <Box>
            <FormControlLabel
              disabled={distributedStatus === DistributedStatus.DISTRIBUTED}
              control={<Checkbox defaultChecked={distribute} />}
              label="Mark all undistributed codes as distributed"
              onChange={handleDistributeUndistributedCodesChange}
            />

            <Typography>
              Recommended to check this checkbox if you are uploading the codes
              to a CMS to prevent duplicates
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            await downloadCSV();
          }}
          color="primary"
          variant="outlined"
          autoFocus
          disabled={loading}
        >
          {loading ? 'Processing...' : 'Download CSV File'}
        </Button>
        <Button onClick={close} color="secondary" variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function getRelevantAccessCodes(
  accessCodes: Array<any>,
  distributedStatus: DistributedStatus
) {
  if (distributedStatus === DistributedStatus.DISTRIBUTED) {
    return accessCodes.filter((p) => p.distributedAt);
  }

  if (distributedStatus === DistributedStatus.UNDISTRIBUTED) {
    return accessCodes.filter((p) => !p.distributedAt);
  }

  return accessCodes;
}

async function exportToCSV(
  accessCodes: Array<any>,
  allTours: Array<{
    tourName: string;
  }>,
  appURL: string
): Promise<string> {
  const rawData = accessCodes
    .map((a) => ({
      link: `${appURL}?rcode=${a.token}`,
      tourName: allTours?.[0]?.tourName + ' Tours',
      code: a.token,
      type: a.accessSubType ? getCamelCaseString(a.accessSubType) : 'Not Set',
    }))
    .sort(sortByTourname);

  return new Promise((resolve, reject) => {
    jsonexport(rawData, function (err: Error, csv: string) {
      if (err) {
        reject(err);
      }

      resolve(csv);
    });
  });
}

type CSVRow = {
  tourName: string;
  code: string;
};

function sortByTourname(a: CSVRow, b: CSVRow) {
  var nameA = a.tourName.toUpperCase(); // ignore upper and lowercase
  var nameB = b.tourName.toUpperCase(); // ignore upper and lowercase

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}
