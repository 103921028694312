import { app, initialState as initialAppState } from './app/reducer';
import { auth, initialState as initialAuthState } from './auth/reducer';
import {
  scripts,
  initialState as initialScriptsState,
} from './scripts/reducer';
import { cart, initialState as initialCartState } from './cart/reducer';
import { modal, initialState as InitialModalState } from './modal/reducer';
import {
  features,
  initialState as InitialFeaturesState,
} from './features/reducer';

import { RootState } from './types';

const initialRootState = {
  app: initialAppState,
  auth: initialAuthState,
  cart: initialCartState,
  features: InitialFeaturesState,
  modal: InitialModalState,
  scripts: initialScriptsState,
};

export function rootReducer(state: RootState = initialRootState, action) {
  return {
    app: app(state.app, action),
    auth: auth(state.auth, action),
    cart: cart(state.cart, action),
    features: features(state.features, action),
    modal: modal(state.modal, action),
    scripts: scripts(state.scripts, action),
  };
}
