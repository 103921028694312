import { useMutation, gql } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  CONSOLE_DistributeAllBundleCodesInDownloadClientRedemptionCodesDialog as Data,
  CONSOLE_DistributeAllBundleCodesInDownloadClientRedemptionCodesDialogVariables as Variables,
} from './__generated__/CONSOLE_DistributeAllBundleCodesInDownloadClientRedemptionCodesDialog';
import {
  DownloadPDFInstructions,
  QRCodeComponent,
} from './DownloadPDFInstructions';

export const DISTRIBUTE_ALL_BUNDLE_CODES_MUTATION = gql`
  mutation CONSOLE_DistributeAllBundleCodesInDownloadClientRedemptionCodesDialog(
    $input: SetUndistributedAccessCodesAsDistributedInput!
  ) {
    result: setUndistributedAccessCodesAsDistributed(input: $input) {
      count
      error {
        message
      }
    }
  }
`;

enum DistributedStatus {
  UNDISTRIBUTED = 'UNDISTRIBUTED',
  DISTRIBUTED = 'DISTRIBUTED',
  BOTH = 'BOTH',
}

interface Props {
  close: () => void;
  data: {
    appURL: string;
    accessCodes: Array<any>;
    allTours: Array<{
      tourName: string;
    }>;
  };
}

export const DownloadClientBundleRedemptionCodesPDF = ({
  close,
  data: { accessCodes, allTours, appURL },
}: Props) => {
  const [qrCodeBase64, setQrCodeBase64] = useState([]);
  const [distributedStatus, setDistributedStatus] = useState<DistributedStatus>(
    DistributedStatus.UNDISTRIBUTED
  );
  const [distribute, setDistribute] = useState<boolean>(
    distributedStatus !== DistributedStatus.DISTRIBUTED
  );
  const [distributeCodes] = useMutation<Data, Variables>(
    DISTRIBUTE_ALL_BUNDLE_CODES_MUTATION
  );

  const downloadPDF = async () => {
    try {
      if (distributedStatus !== DistributedStatus.DISTRIBUTED && distribute) {
        const tokens = accessCodes.map((p) => p.token);
        await distributeCodes({
          variables: {
            input: {
              tokens,
            },
          },
        });
      }

      close();
    } catch (error) {
      toast.error('An error occurred while generating PDF file', {
        toastId: 'redeem_codes_pdf_error',
        hideProgressBar: true,
        pauseOnHover: false,
      });
    }
  };

  const handleDistributedStatusChange = (event) => {
    setDistributedStatus(event.target.value);
  };

  const handleDistributeUndistributedCodesChange = (event) => {
    setDistribute(event.target.checked);
  };
  const relevantAccessCodes = getRelevantAccessCodes(
    accessCodes,
    distributedStatus
  );
  const toPrintDocument = (
    <div>
      {relevantAccessCodes.map(({ token }, index) => (
        <DownloadPDFInstructions
          key={index}
          index={index}
          token={token}
          qrCodeBase64={qrCodeBase64?.[index] || ''}
          appURL={appURL}
        />
      ))}
    </div>
  );
  return (
    <>
      {relevantAccessCodes.map(({ token }, index) => {
        return (
          <QRCodeComponent
            key={index}
            index={index}
            token={token}
            setQrCodeBase64={setQrCodeBase64}
            appURL={appURL}
          />
        );
      })}
      <Dialog
        open
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Download Bundle Redeem Codes as a PDF File
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box>
              <Typography gutterBottom>
                If you want to feed the bundle redeem codes into a separate CMS
                or simply to backup your codes, this feature will be helpful.
              </Typography>

              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="Distributed status of the bundle redeem codes to download"
                  value={distributedStatus}
                  onChange={handleDistributedStatusChange}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value={DistributedStatus.UNDISTRIBUTED}
                    control={<Radio />}
                    label="Download only the undistributed bundle redeem codes"
                  />
                  <FormControlLabel
                    value={DistributedStatus.DISTRIBUTED}
                    control={<Radio />}
                    label="Download only the distributed bundle redeem codes"
                  />
                  <FormControlLabel
                    value={DistributedStatus.BOTH}
                    control={<Radio />}
                    label="Download all bundle redeem codes"
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <Divider />

            <Box>
              <FormControlLabel
                disabled={distributedStatus === DistributedStatus.DISTRIBUTED}
                control={<Checkbox defaultChecked={distribute} />}
                label="Mark all undistributed codes as distributed"
                onChange={handleDistributeUndistributedCodesChange}
              />

              <Typography>
                Recommended to check this checkbox if you are uploading the
                codes to a CMS to prevent duplicates
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <PDFDownloadLink
            document={toPrintDocument}
            fileName={`AncientWorld_BundleRedeemCodes_${format(
              new Date(),
              'yyyyMMdd'
            )}.pdf`}
          >
            {({ blob, url, loading, error }) => (
              <Button
                onClick={async () => {
                  await downloadPDF();
                }}
                color="primary"
                variant="outlined"
                autoFocus
                disabled={loading}
              >
                {loading ? 'Processing...' : 'Download PDF'}
              </Button>
            )}
          </PDFDownloadLink>
          <Button onClick={close} color="secondary" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function getRelevantAccessCodes(
  accessCodes: Array<any>,
  distributedStatus: DistributedStatus
) {
  if (distributedStatus === DistributedStatus.DISTRIBUTED) {
    return accessCodes.filter((p) => p.distributedAt);
  }

  if (distributedStatus === DistributedStatus.UNDISTRIBUTED) {
    return accessCodes.filter((p) => !p.distributedAt);
  }

  return accessCodes;
}
