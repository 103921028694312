import { createTransform } from 'redux-persist';
import { initialState as scriptsInitialState } from './scripts/reducer';
import { initialState as modalsInitialState } from './modal/reducer';
import { initialState as appInitialState } from './app/reducer';
import { initialState as authInitialState } from './auth/reducer';
import { AuthState } from './auth/types';
import { AppState } from './app/types';
import { initialState as cartInitialState } from './cart/reducer';
import { initialState as featuresInitialState } from './features/reducer';

const transform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    return inboundState;
  },
  // transform state being rehydrated
  (outboundState, key) => {
    if (key === 'app') {
      const newAppState = outboundState as AppState;

      return { ...appInitialState, ...newAppState };
    }

    if (key === 'scripts') {
      return scriptsInitialState;
    }

    if (key === 'modal') {
      return modalsInitialState;
    }

    if (key === 'auth') {
      const authState = outboundState as AuthState;

      return { ...authInitialState, ...authState };
    }

    if (key === 'cart') {
      const cartState = outboundState as any;

      // support legacy cart state
      if (Array.isArray(cartState)) {
        return {
          ...cartInitialState,
          items: cartState,
        };
      }

      return {
        ...cartInitialState,
        ...cartState,
      };
    }

    if (key === 'features') {
      return featuresInitialState;
    }

    // convert mySet to an Array.
    return outboundState;
  }
  // define which reducers this transform gets called for.
  //   { whitelist: ['app'] }
);

export const transforms = [transform];
