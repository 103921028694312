import { RootState } from '../types';
import { Feature } from './types';

export function selectFeatures(state: RootState) {
  return state.features?.features;
}

export function selectFeature(feature: Feature) {
  return function (state: RootState) {
    return selectFeatures(state)?.[feature];
  };
}

export function selectFeaturesLoaded(state: RootState) {
  console.log('state.features:', state.features);
  return !!state.features?.loaded;
}
