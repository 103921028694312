export enum Feature {
  LVR_FT_CLIENT_ORDERS_PAGINATION = 'LVR_FT_CLIENT_ORDERS_PAGINATION',
}

export type FeaturesState = {
  loaded: boolean;
  features: Record<Feature, boolean>;
};

export const SET_FEATURES = 'SET_FEATURES';

export type SetFeaturesPayload = Array<{
  name: Feature;
  value: boolean;
}>;

interface SetFeaturesAction {
  type: typeof SET_FEATURES;
  payload: SetFeaturesPayload;
}

export type FeatureActionTypes = SetFeaturesAction;
