import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { selectFeaturesLoaded } from '../store/features/selectors';
import { Feature } from '../store/features/types';
import { nonNullable } from '../utils/nonNullable';
import { setFeatures } from '../store/features/actions';

const useFeaturesInitializer = () => {
  const dispatch = useDispatch();
  const featuresLoaded = useSelector(selectFeaturesLoaded);

  useEffect(() => {
    if (!featuresLoaded) {
      const cookies = Cookies.get();

      const featurePayloads = Object.keys(cookies)
        .map((cookieName) => {
          if (cookieName.startsWith('LVR_FT_')) {
            const name = cookieName as Feature;
            const value = cookies[cookieName] === 'true';

            return { name, value };
          }

          return null;
        })
        .filter(nonNullable);

      dispatch(setFeatures(featurePayloads));
    }
  }, [featuresLoaded, dispatch]);

  return featuresLoaded;
};

export default useFeaturesInitializer;
